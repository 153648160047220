import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import { FaEdit } from 'react-icons/fa'
import LinkOrAction from './LinkOrAction'
import { usePalette } from 'react-palette'

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null
}

const SectionHero = ({ theme, block, isAdmin, section_id }) => {
    const backgroundFluidImageStack = [block.heroImage.fluid, `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25))`].reverse()
    const { data, loading, error } = usePalette(block.heroImage.fluid.src)

    let textColor = '#FFFFFF'
    // if (data.vibrant) {
    //     const rgb = hexToRgb(data.vibrant)
    //     if (rgb) {
    //         const { r, g, b } = rgb
    //         console.log('RGB is', rgb, r * 0.299 + g * 0.587 + b * 0.114)
    //         if (r * 0.299 + g * 0.587 + b * 0.114 > 186) {
    //             textColor = '#000000'
    //         } else {
    //             textColor = '#FFFFFF'
    //         }
    //     }
    // }

    return (
        <BackgroundImage
            id={section_id}
            className="block hero-block bg-accent outer"
            style={{
                overflow: 'hidden',
            }}
            fluid={backgroundFluidImageStack}
        >
            {isAdmin && (
                <Link
                    style={{ position: 'absolute', top: 16, right: 16, color: textColor }}
                    to={`https://app.contentful.com/spaces/fsmkvylf1g83/entries/${block.contentful_id}`}
                >
                    <FaEdit /> Edit
                </Link>
            )}

            <div
                className="inner"
                style={{
                    marginTop: '3.16667em',
                }}
            >
                <div className="cell block-content">
                    <h2 className="block-title underline" style={{ color: textColor }}>
                        {block.title}
                    </h2>
                    {block.body && (
                        <div className="block-copy">
                            <div
                                style={{ color: textColor }}
                                dangerouslySetInnerHTML={{
                                    __html: block.body.childMarkdownRemark.html,
                                }}
                            />
                        </div>
                    )}

                    {block.callToActions && block.callToActions.length > 0 && (
                        <div className="block-buttons">
                            {block.callToActions.map(({ title, subtitle }, index) => (
                                <LinkOrAction key={index} title={title} subtitle={subtitle} />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </BackgroundImage>
    )
}

export default SectionHero
