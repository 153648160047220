import React from 'react'
const Profile = () => (
    <>
        <h1>Logged in</h1>
    </>
)

/*
<ul>
<li>Name: Your name will appear here</li>
<li>E-mail: And here goes the mail</li>
</ul>
*/

export default Profile
