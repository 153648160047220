import React from 'react'
import { navigate } from 'gatsby'
import { handleLogin, isLoggedIn } from '../services/auth'

import axios from 'axios'

class Login extends React.Component {
    state = {
        email: ``,
        password: ``,
    }

    handleUpdate = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    handleSubmit = (event) => {
        axios
            .post('https://private.nano-eh.syncode.it/login', {
                user: { email: this.state.email, password: this.state.password },
            })
            .then(function (response) {
                console.log(response.data.user)
                handleLogin(response.data.user)
                setTimeout(() => {
                    navigate(`/private-area/storage`)
                }, 100)
            })
            .catch(function (error) {
                console.log(error)
            })

        event.preventDefault()
    }

    render() {
        if (isLoggedIn()) {
            navigate(`/private-area/storage`)
        }

        return (
            <>
                <h1>Log in</h1>
                <form
                    method="post"
                    onSubmit={(event) => {
                        this.handleSubmit(event)
                    }}
                >
                    <label>
                        Username
                        <input type="text" name="email" onChange={this.handleUpdate} />
                    </label>
                    <label>
                        Password
                        <input type="password" name="password" onChange={this.handleUpdate} />
                    </label>
                    <input type="submit" value="Log In" />
                </form>
            </>
        )
    }
}

export default Login
