import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import styles from './hero.module.css'

export default ({ data }) =>
    null
    // <div className={styles.hero}>
    //   <GatsbyImage
    //     className={styles.heroImage}
    //     alt={data.name}
    //     fluid={data.heroImage.fluid}
    //   />
    //   <div className={styles.heroDetails}>
    //     <h3 className={styles.heroHeadline}>{data.name}</h3>
    //     <p className={styles.heroTitle}>{data.title}</p>
    //     <p>{data.shortBio.shortBio}</p>
    //   </div>
    // </div>
