import React from 'react'
import { FaEdit, FaEnvelope, FaMapPin, FaPhone } from 'react-icons/fa'
import { Link } from '../utils'

const searchIndices = [{ name: `Pages`, title: `Pages` }]

const SectionContacts = ({ theme, block, isAdmin, section_id }) => {
    return (
        <section className="site-header outer colophon">
            {isAdmin && (
                <Link
                    style={{ position: 'absolute', top: 16, right: 16 }}
                    to={`https://app.contentful.com/spaces/fsmkvylf1g83/entries/${block.contentful_id}`}
                >
                    <FaEdit /> Edit
                </Link>
            )}
            <div className="inner">
                <div className="cell">
                    <h6 className="primary-text" style={{ marginBottom: 0, textAlign: 'center', textTransform: 'uppercase' }}>
                        {block.title}
                    </h6>
                    {/* <hr className="hr-primary" style={{ margin: 'auto', height: 4, width: 300, marginBottom: 40 }} /> */}
                </div>
                <div className="site-header-inside">
                    <div style={{ margin: 'auto' }} className="site-navigation">
                        <div>
                            <ul style={{ marginBottom: 0 }}>
                                <li className="menu-item">
                                    <FaPhone /> {block.telephone}
                                </li>
                                <li className="menu-item">
                                    <FaEnvelope /> {block.email}
                                </li>
                                <li className="menu-item">
                                    <FaMapPin /> {block.address}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionContacts
