import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import AzLayout from '../components/AzLayout'

// import heroStyles from "../components/hero.module.css";
import { CtaButtons, SectionCta, SectionFeatures, SectionHero } from '../components'
import Language from '../components/Language'
import SectionClearText from '../components/SectionClearText'
import SectionMosaic from '../components/SectionMosaic'
import SectionNewsletter from '../components/SectionNewsletter'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SectionContacts from '../components/SectionContacts'
import SectionSocialMedia from '../components/SectionSocialMedia'
import SectionFooter from '../components/SectionFooter'
import SectionRecentPosts from '../components/SectionRecentPosts'
import SectionImageText from '../components/SectionImageText'
import SectionImage from '../components/SectionImage'
import SectionCarousel from '../components/SectionCarousel'
import { Link } from 'gatsby-plugin-intl'
import { FaEdit } from 'react-icons/fa'
import SectionDoubleText from '../components/SectionDoubleText'
import SectionPrivateArea from '../components/SectionPrivateArea'
import SectionYoutubeVideo from '../components/SectionYoutubeVideo'
import SectionTwitter from '../components/SectionTwitter'
import { FacebookProvider } from 'react-facebook'
import SectionCalendar from '../components/SectionCalendar'
import SectionMap from '../components/SectionMap'
import SectionFacebook from '../components/SectionFacebook'
import SectionInstagram from '../components/SectionInstagram'

const themes = ['main', 'alt']

const appId = '456538982275305'

class LandingTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isAdmin: false,
        }
    }

    render() {
        const post = get(this.props, 'data.contentfulLanding')
        const siteTitle = get(this.props, 'data.site.siteMetadata.title')
        const theme = themes[0]

        let { isAdmin } = this.state

        const isEditing = this.props.data.allContentfulGlobalSettings.edges[0].node.editing
        if (isEditing) isAdmin = true

        let header = {}
        let footer = {}
        if (this.props.data.allContentfulNavigation) {
            for (const edge of this.props.data.allContentfulNavigation.edges) {
                const block = edge.node
                if (block.links && block.links.length > 0 && block.links[0].node_locale === this.props.pageContext.language) {
                    if (block.type === 'header') {
                        header = block
                    }
                    if (block.type === 'footer') {
                        footer = block
                    }
                }
            }
        }

        header.data = { site: this.props.data.site, url: this.props.data.url }
        footer.data = { site: this.props.data.site, url: this.props.data.url }

        header.subMenu = this.props.data.contentfulLanding.subMenu

        const content = []
        let index = 0
        if (this.props.data.contentfulLanding.landingBlocks) {
            for (const block of this.props.data.contentfulLanding.landingBlocks) {
                if (!block.internal || block.hidden) continue
                const type = block.internal.type
                switch (type) {
                    case 'ContentfulAuthor':
                        content.push(<SectionCta isAdmin={isAdmin} key={index} {...{ ...this.props }} block={block} theme={theme} />)
                        break
                    case 'ContentfulMosaic':
                        content.push(
                            <SectionMosaic isAdmin={isAdmin} key={index} {...{ ...this.props, section_id: 'mosaic' }} block={block} theme={theme} />
                        )
                        break
                    case 'ContentfulHero':
                        content.push(
                            <SectionHero isAdmin={isAdmin} key={index} {...{ ...this.props, section_id: 'hero' }} block={block} theme={theme} />
                        )
                        break
                    case 'ContentfulImageText':
                        content.push(<SectionImageText isAdmin={isAdmin} key={index} {...{ ...this.props }} block={block} theme={theme} />)
                        break
                    case 'ContentfulImageOnly':
                        content.push(<SectionImage isAdmin={isAdmin} key={index} {...{ ...this.props }} block={block} theme={theme} />)
                        break
                    case 'ContentfulPrivateArea':
                        content.push(<SectionPrivateArea isAdmin={isAdmin} key={index} {...{ ...this.props }} block={block} theme={theme} />)
                        break
                    case 'ContentfulClearText':
                        content.push(<SectionClearText isAdmin={isAdmin} key={index} {...{ ...this.props }} block={block} theme={theme} />)
                        break
                    case 'ContentfulDoubleText':
                        content.push(<SectionDoubleText isAdmin={isAdmin} key={index} {...{ ...this.props }} block={block} theme={theme} />)
                        break
                    case 'ContentfulRecentPosts':
                        content.push(<SectionRecentPosts isAdmin={isAdmin} key={index} {...{ ...this.props }} block={block} theme={theme} />)
                        break
                    case 'ContentfulNewsletter':
                        content.push(<SectionNewsletter isAdmin={isAdmin} key={index} {...{ ...this.props }} block={block} theme={theme} />)
                        break
                    case 'ContentfulContacts':
                        content.push(<SectionContacts isAdmin={isAdmin} key={index} {...{ ...this.props }} block={block} theme={theme} />)
                        break
                    case 'ContentfulSocialMedia':
                        content.push(<SectionSocialMedia isAdmin={isAdmin} key={index} {...{ ...this.props }} block={block} theme={theme} />)
                        break
                    case 'ContentfulCarousel':
                        content.push(<SectionCarousel isAdmin={isAdmin} key={index} {...{ ...this.props }} block={block} theme={theme} />)
                        break
                    case 'ContentfulFooter':
                        content.push(<SectionFooter isAdmin={isAdmin} key={index} {...{ ...this.props }} block={block} theme={theme} />)
                        break
                    case 'ContentfulYoutubeVideo':
                        content.push(
                            <SectionYoutubeVideo
                                key={index}
                                {...{ ...this.props, section_id: 'features', features: [block] }}
                                block={block}
                                theme={theme}
                            />
                        )
                        break
                    case 'ContentfulFacebook':
                        content.push(
                            <SectionFacebook
                                key={index}
                                {...{ ...this.props, section_id: 'features', features: [block] }}
                                block={block}
                                theme={theme}
                            />
                        )
                        break
                    case 'ContentfulInstagramPost':
                        content.push(
                            <SectionInstagram
                                key={index}
                                {...{ ...this.props, section_id: 'features', features: [block] }}
                                block={block}
                                theme={theme}
                            />
                        )
                        break
                    case 'ContentfulTwitter':
                        content.push(
                            <SectionTwitter
                                key={index}
                                {...{ ...this.props, section_id: 'features', features: [block] }}
                                block={block}
                                theme={theme}
                            />
                        )
                        break
                    case 'ContentfulCalendar':
                        content.push(
                            <SectionCalendar
                                key={index}
                                {...{ ...this.props, section_id: 'features', features: [block] }}
                                block={block}
                                theme={theme}
                            />
                        )
                        break
                    case 'ContentfulFeatures':
                        content.push(
                            <SectionFeatures
                                key={index}
                                {...{ ...this.props, section_id: 'features', features: [block] }}
                                block={block}
                                theme={theme}
                            />
                        )
                        break
                    case 'ContentfulMap':
                        content.push(
                            <SectionMap key={index} {...{ ...this.props, section_id: 'features', features: [block] }} block={block} theme={theme} />
                        )
                        break
                    case 'ContentfulNavigation':
                        if (block.type === 'footer') {
                            content.push(<Footer isAdmin={isAdmin} key={index} data={this.props.data} {...block} block={block} theme={theme} />)
                        }
                    default:
                        break
                }
                index++
            }
        }

        return (
            <FacebookProvider appId={appId}>
                <AzLayout {...this.props}>
                    <Header isAdmin={isAdmin} {...header} />
                    {content}
                </AzLayout>
            </FacebookProvider>
        )
    }
}

export default LandingTemplate

export const pageQuery = graphql`
    query LandingBySlug($locale: String, $slug: String!) {
        allContentfulGlobalSettings {
            edges {
                node {
                    contentful_id
                    id
                    editing
                }
            }
        }
        allContentfulNavigation {
            edges {
                node {
                    contentful_id
                    id
                    type
                    node_locale
                    links {
                        id
                        title
                        node_locale
                        shortName
                        navButtonType
                        slug
                        icon {
                            iconName
                        }
                    }
                    logo {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }
        contentfulLanding(node_locale: { eq: $locale }, slug: { eq: $slug }) {
            title
            publishDate(formatString: "MMMM Do, YYYY")
            subMenu {
                links {
                    id
                    title
                    node_locale
                    shortName
                    slug
                    navButtonType
                }
            }
            landingBlocks {
                ... on ContentfulNavigation {
                    contentful_id
                    id
                    type
                    node_locale
                    logo {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                    }
                    links {
                        id
                        title
                        node_locale
                        shortName
                        slug
                        navButtonType
                    }
                    title
                    internal {
                        type
                    }
                }
                ... on ContentfulSocialMedia {
                    contentful_id
                    id
                    title
                    facebook
                    linkedin
                    twitter
                    instagram
                    createdAt
                    updatedAt
                    internal {
                        type
                    }
                }
                ... on ContentfulYoutubeVideo {
                    contentful_id
                    id
                    title
                    videoId
                    internal {
                        type
                    }
                }
                ... on ContentfulFacebook {
                    contentful_id
                    id
                    title
                    type
                    url
                    internal {
                        type
                    }
                }
                ... on ContentfulInstagramPost {
                    contentful_id
                    id
                    title
                    url
                    internal {
                        type
                    }
                }
                ... on ContentfulTwitter {
                    contentful_id
                    id
                    title
                    type
                    referenceId
                    shareUrl
                    shareContent
                    internal {
                        type
                    }
                }
                ... on ContentfulMap {
                    contentful_id
                    id
                    title
                    googleMapsString
                    internal {
                        type
                    }
                }
                ... on ContentfulCalendar {
                    contentful_id
                    id
                    title
                    embed {
                        childMarkdownRemark {
                            html
                        }
                    }
                    internal {
                        type
                    }
                }
                ... on ContentfulCarousel {
                    contentful_id
                    id
                    title
                    images {
                        ... on ContentfulAsset {
                            fluid(maxWidth: 160, background: "rgb:000000") {
                                ...GatsbyContentfulFluid
                            }
                            fixed {
                                ...GatsbyContentfulFixed_tracedSVG
                            }
                        }
                    }
                    internal {
                        type
                    }
                }
                ... on ContentfulRecentPosts {
                    contentful_id
                    id
                    node_locale
                    post {
                        node_locale
                        title
                        shortName
                        slug
                        heroImage {
                            fluid(maxWidth: 1180, background: "rgb:000000") {
                                ...GatsbyContentfulFluid
                            }
                        }
                        description {
                            id
                            childMarkdownRemark {
                                id
                                html
                            }
                        }
                        body {
                            id
                            childMarkdownRemark {
                                id
                                html
                            }
                        }
                        author {
                            name
                            title
                            company
                            shortBio {
                                id
                                childMarkdownRemark {
                                    id
                                    html
                                }
                            }
                            email
                            phone
                            facebook
                            twitter
                            github
                            image {
                                fluid(maxWidth: 1180, background: "rgb:000000") {
                                    ...GatsbyContentfulFluid
                                }
                            }
                        }
                        publishDate(formatString: "MMMM Do, YYYY")
                        tags
                    }
                    createdAt
                    updatedAt
                    internal {
                        type
                    }
                }
                ... on ContentfulFooter {
                    contentful_id
                    id
                    footerText {
                        id
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                    createdAt
                    updatedAt
                    internal {
                        type
                    }
                }
                ... on ContentfulContacts {
                    contentful_id
                    id
                    title
                    telephone
                    email
                    address
                    createdAt
                    updatedAt
                    internal {
                        type
                    }
                }
                ... on ContentfulAuthor {
                    contentful_id
                    id
                    name
                    slug
                    bio
                    featured
                    social
                    createdAt
                    updatedAt
                    internal {
                        type
                    }
                    hidden
                }
                ... on ContentfulPrivateArea {
                    contentful_id
                    id
                    title
                    internal {
                        type
                    }
                }
                ... on ContentfulImageOnly {
                    contentful_id
                    id
                    title
                    createdAt
                    updatedAt
                    image {
                        fluid(maxHeight: 500, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                    }
                    reverse
                    internal {
                        type
                    }
                    body {
                        id
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                    hidden
                }
                ... on ContentfulImageText {
                    contentful_id
                    id
                    title
                    createdAt
                    updatedAt
                    image {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                    }
                    reverse
                    internal {
                        type
                    }
                    body {
                        id
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                    callToAction {
                        title
                        subtitle
                    }
                    hidden
                }
                ... on ContentfulClearText {
                    contentful_id
                    id
                    title
                    type
                    createdAt
                    updatedAt
                    internal {
                        type
                    }
                    body {
                        id
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                    callToAction {
                        title
                        subtitle
                    }
                    hidden
                }
                ... on ContentfulDoubleText {
                    contentful_id
                    id
                    title
                    createdAt
                    updatedAt
                    internal {
                        type
                    }
                    leftText {
                        id
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                    rightText {
                        id
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                    callToAction {
                        title
                        subtitle
                    }
                    hidden
                }
                ... on ContentfulNewsletter {
                    contentful_id
                    id
                    title
                    createdAt
                    updatedAt
                    internal {
                        type
                    }
                    body {
                        id
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                    placeholder
                    subscribe
                    heroImage {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                    }
                    hidden
                }
                ... on ContentfulHero {
                    contentful_id
                    id
                    title
                    slug
                    body {
                        childMarkdownRemark {
                            html
                        }
                    }
                    heroImage {
                        id
                    }
                    createdAt
                    updatedAt
                    internal {
                        type
                    }
                    heroImage {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                    }
                    callToActions {
                        title
                        subtitle
                    }
                    hidden
                }
                ... on ContentfulMosaic {
                    contentful_id
                    id
                    title
                    body {
                        childMarkdownRemark {
                            html
                        }
                    }
                    heroImage {
                        id
                    }
                    createdAt
                    updatedAt
                    internal {
                        type
                    }
                    heroImage {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                    }
                    callToAction {
                        title
                        subtitle
                    }
                    images {
                        id
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                    }
                    hidden
                }
                ... on ContentfulFeatures {
                    contentful_id
                    id
                    title
                    slug
                    body {
                        childMarkdownRemark {
                            html
                        }
                    }
                    heroImage {
                        id
                    }
                    createdAt
                    updatedAt
                    internal {
                        type
                    }
                    heroImage {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                    }
                    hidden
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                base_font
                site_url
                footer {
                    content
                    has_nav
                    nav_links {
                        ... on SiteSiteMetadataFooterNav_links {
                            label
                            style
                            url
                        }
                    }
                    sections {
                        ... on SiteSiteMetadataFooterSections {
                            content
                            form_fields {
                                ... on SiteSiteMetadataFooterSectionsForm_fields {
                                    default_value
                                    input_type
                                    is_required
                                    label
                                    name
                                }
                            }
                            form_id
                            hide_labels
                            image
                            image_alt
                            image_url
                            nav_links {
                                ... on SiteSiteMetadataFooterSectionsNav_links {
                                    label
                                    style
                                    url
                                }
                            }
                            submit_label
                            title
                            type
                        }
                    }
                }
                header {
                    has_nav
                    logo_img
                    logo_img_alt
                    nav_links {
                        ... on SiteSiteMetadataHeaderNav_links {
                            label
                            style
                            url
                        }
                    }
                }
                palette
            }
        }
    }
`
