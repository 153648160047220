import React from 'react'
import _ from 'lodash'

import { withPrefix, markdownify } from '../utils'
import CtaButtons from './CtaButtons'
import Img from 'gatsby-image'
import LinkOrAction from './LinkOrAction'
import { Link } from 'gatsby-plugin-intl'
import { FaEdit } from 'react-icons/fa'
import { Router } from '@reach/router'
import PrivateRoute from './PrivateRoute'
import { getUser, isLoggedIn, logout } from '../services/auth'
import Profile from './Profile'
import Login from './Login'
import Storage from './Storage'
import { navigate } from 'gatsby-link'

const SectionPrivateArea = ({ block, isAdmin, section_id }) => {
    return (
        <section id={section_id} className="block hero-block outer" style={{ minHeight: 800, color: 'black' }}>
            {isLoggedIn() ? (
                <>
                    <button
                        onClick={() => {
                            logout(() => {
                                console.log('Done')
                                navigate('/private-area')
                            })
                        }}
                    >
                        LOGOUT
                    </button>
                </>
            ) : (
                <>
                    You should <Link style={{color: 'black'}} to="/private-area/login">log in</Link> to see restricted content
                </>
            )}
            <Router>
                <Login path="/private-area/login" />
                <Login path="/en-US/private-area/login" />

                <PrivateRoute path="/private-area/profile" component={Profile} />
                <PrivateRoute path="/en-US/private-area/profile" component={Profile} />

                <PrivateRoute path="/private-area/storage" component={Storage} />
                <PrivateRoute path="/en-US/private-area/storage" component={Storage} />
            </Router>
        </section>
    )
}
export default SectionPrivateArea
