import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import { FaEdit, FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa'

const searchIndices = [{ name: `Pages`, title: `Pages` }]

const SectionRecentPosts = ({ block, isAdmin, section_id }) => {
    return (
        <section className="site-header outer colophon">
            {isAdmin && (
                <Link
                    style={{ position: 'absolute', top: 16, right: 16 }}
                    to={`https://app.contentful.com/spaces/fsmkvylf1g83/entries/${block.contentful_id}`}
                >
                    <FaEdit /> Edit
                </Link>
            )}
            <div className="inner">
                <div className="cell">
                    <h6 className="primary-text" style={{ marginTop: 16, marginBottom: 0, textAlign: 'center', textTransform: 'uppercase' }}>
                        {block.title}
                    </h6>
                    {/* <hr className="hr-primary" style={{ margin: 'auto', height: 4, width: 300, marginBottom: 40 }} /> */}
                </div>
                <div className="site-header-inside">
                    <div style={{ margin: 'auto' }} className="site-navigation">
                        <ul style={{ padding: 0 }}>
                            <li style={{ marginLeft: 0 }} className="menu-item">
                                <a href={block.facebook}>
                                    <FaFacebook />
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href={block.linkedin}>
                                    <FaLinkedin />
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href={block.twitter}>
                                    <FaTwitter />
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href={block.instagram}>
                                    <FaInstagram />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionRecentPosts
