import _ from 'lodash'
import React from 'react'
import { FaEdit } from 'react-icons/fa'
import { classNames, Link } from '../utils'
import Action from './Action'
import components from './index'

const searchIndices = [{ name: `Pages`, title: `Pages` }]

const Footer = (props) => {
    const { theme, block, isAdmin, section_id, title, data, links } = props
    /*{_.get(props, 'data.site.siteMetadata.footer.sections', null) &&
                        _.size(_.get(props, 'data.site.siteMetadata.footer.sections', null)) > 0 && (
                            <div className="footer-top outer">
                                <div className="inner">
                                    <div className="grid footer-widgets">
                                        {_.map(_.get(props, 'data.site.siteMetadata.footer.sections', null), (section, section_idx) => {
                                            let component = _.upperFirst(_.camelCase(_.get(section, 'type', null)))
                                            let Component = components[component]
                                            console.log('loading component', component, 'with props', section, props)

                                            switch (component) {
                                                case 'footer_text':
                                                    return null
                                                case 'footer_form':
                                                    return null
                                                default:
                                                    break
                                            }

                                            if (section.title === 'Company') {
                                                return null
                                            }

                                            return <Component key={section_idx} {...props} section={section} site={props.data.site} />
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}*/
    switch (theme) {
        case 'main':
            return (
                <footer id="colophon" className="site-footer">
                    <div className="footer-bottom outer">
                        <div className="inner">
                            {_.get(props, 'links', null) && (
                                <div className="footer-nav">
                                    <ul className="menu">
                                        {_.map(_.get(props, 'links', null), (action, action_idx) => (
                                            <li key={action_idx} className="menu-item">
                                                <Action {...props} action={action} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <div className="site-info">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: _.get(props, 'data.site.siteMetadata.footer.content', null),
                                    }}
                                />
                                &nbsp;
                                {_.map(_.get(props, 'data.site.siteMetadata.footer.links', null), (action, action_idx) => (
                                    <Action key={action_idx} {...props} action={action} />
                                ))}
                            </div>
                        </div>
                    </div>
                </footer>
            )
        case 'alt':
            return (
                <header className="site-header outer" style={{ marginTop: 0 }}>
                    {isAdmin && (
                        <Link
                            style={{ position: 'absolute', top: 16, right: 16 }}
                            to={`https://app.contentful.com/spaces/fsmkvylf1g83/entries/${block.contentful_id}`}
                        >
                            <FaEdit /> Edit
                        </Link>
                    )}
                    <div className="inner">
                        <div className="cell">
                            <h4 className="primary-text" style={{ marginBottom: 0, textAlign: 'center', textTransform: 'uppercase' }}>
                                {title}
                            </h4>
                            {/* <hr className="hr-primary" style={{ margin: 'auto', height: 4, width: 300, marginBottom: 40 }} /> */}
                        </div>
                        <div className="site-header-inside">
                            <nav style={{ margin: 'auto' }} className="" aria-label="Main Navigation">
                                <div className="">
                                    <ul className="menu">
                                        {_.map(links, (action, action_idx) => {
                                            let page_url = _.trim(_.get(props, 'data.url', null), '/')
                                            let action_url = _.trim(_.get(action, 'slug', null), '/')
                                            let action_style = _.get(action, 'style', null) || 'link'

                                            return (
                                                <li key={action_idx} className={'footer-menu-item'} style={action_idx === 0 ? { marginLeft: 0 } : {}}>
                                                    <Action
                                                        {...props}
                                                        action={action}
                                                        uppercase={true}
                                                        site_url={props.data.site.siteMetadata.site_url}
                                                    />
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
            )
    }
}

export default Footer
