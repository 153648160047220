import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import styles from './article-preview.module.css'

export default ({ article }) =>
    null
    // <div className={styles.preview}>
    //   <GatsbyImage alt="" fluid={article.heroImage.fluid} />
    //   <h3 className={styles.previewTitle}>
    //     <Link to={`/blog/${article.slug}`}>{article.title}</Link>
    //   </h3>
    //   <small>{article.publishDate}</small>
    //   <p
    //     dangerouslySetInnerHTML={{
    //       __html: article.description.childMarkdownRemark.html,
    //     }}
    //   />
    // </div>
