import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'

export default () =>
    null
    // <nav role="navigation">
    //   <ul className={styles.navigation}>
    //     <li className={styles.navigationItem}>
    //       <Link to="/">Home</Link>
    //     </li>
    //     <li className={styles.navigationItem}>
    //       <Link to="/blog/">Blog</Link>
    //     </li>
    //     <li className={styles.navigationItem}>
    //       <Link to="/page/test-first-landing">Chi siamo</Link>
    //     </li>
    //   </ul>
    // </nav>
