import React from 'react'
import _ from 'lodash'

import { htmlToReact, withPrefix, markdownify } from '../utils'
import CtaButtons from './CtaButtons'
import Img from 'gatsby-image'

export default class SectionFeatures extends React.Component {
    render() {
        let section = this.props.block
        let feature = this.props.block

        return (
            <section id={_.get(section, 'section_id', null)} className={'block features-block bg-' + _.get(section, 'background', null) + ' outer'}>
                <div className="block-header inner-small">
                    {_.get(section, 'title', null) && <h2 className="block-title">{_.get(section, 'title', null)}</h2>}
                    {_.get(section, 'subtitle', null) && <p className="block-subtitle">{htmlToReact(_.get(section, 'subtitle', null))}</p>}
                </div>
                {_.get(section, 'features', null) && (
                    <div className="inner">
                        <div className="block-item">
                            <div className="grid">
                                {_.get(feature, 'heroImage', null) && (
                                    <div className="cell block-preview">
                                        <Img
                                            // className={heroStyles.heroImage}
                                            alt={feature.title}
                                            fluid={feature.heroImage.fluid}
                                        />
                                    </div>
                                )}
                                <div className="cell block-content">
                                    <h3 className="block-title underline">{_.get(feature, 'title', null)}</h3>
                                    <div className="block-copy">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: feature.body.childMarkdownRemark.html,
                                            }}
                                        />
                                    </div>
                                    {_.get(feature, 'actions', null) && (
                                        <div className="block-buttons">
                                            <CtaButtons {...this.props} actions={_.get(feature, 'actions', null)} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        )
    }
}
