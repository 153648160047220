import React from 'react'
import _ from 'lodash'

import { withPrefix, markdownify } from '../utils'
import CtaButtons from './CtaButtons'
import Img from 'gatsby-image'
import LinkOrAction from './LinkOrAction'
import { Link } from 'gatsby-plugin-intl'
import { FaEdit } from 'react-icons/fa'
import { Carousel } from 'bootstrap'
import { Card, Col, Container, Row } from 'react-bootstrap'

const SectionCarousel = ({ block, isAdmin, section_id }) => {
    if (typeof window === 'undefined') {
        return null
    }
    const { images } = block
    return (
        <Container className="carousel-block">
            <Row style={{ width: '100%' }}>
                <Col md={1} className="hidden-xs hidden-sm" />
                {images &&
                    images.map((image, index) => {
                        return (
                            <Col style={{ height: 256 }} key={index} xs={12} sm={6} md={1}>
                                <Img
                                    style={{ height: 256 }}
                                    fluid={image.fluid}
                                    imgStyle={{
                                        objectFit: 'contain',
                                        objectPosition: 'center center',
                                        height: 256,
                                        width: '100%',
                                    }}
                                />
                            </Col>
                        )
                    })}
                <Col md={1} className="hidden-xs hidden-sm" />
            </Row>
        </Container>
    )
}
export default SectionCarousel
