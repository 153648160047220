import React from 'react'
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl'

const languageName = {
    'en-US': 'en',
    it: 'it',
}

const Language = () => (
    <div>
        <IntlContextConsumer>
            {({ languages, language: currentLocale }) => {
                const content = languages.map((language, index) => (
                    <>
                        <a
                            key={language}
                            onClick={() => changeLocale(language)}
                            style={{
                                color: currentLocale === language ? `blue` : `black`,
                                margin: 10,
                                textDecoration: `underline`,
                                cursor: `pointer`,
                            }}
                        >
                            {languageName[language]}
                        </a>
                        {index !== languages.length - 1 && (
                            <div
                                style={{
                                    display: 'inline-block',
                                    height: 22,
                                    width: 2,
                                    backgroundColor: 'grey',
                                    transform: 'translate(0px, 6px)',
                                }}
                            />
                        )}
                    </>
                ))

                return content
            }}
        </IntlContextConsumer>
    </div>
)

export default Language
