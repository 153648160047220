import React from 'react'
import _ from 'lodash'

import { withPrefix, markdownify } from '../utils'
import CtaButtons from './CtaButtons'
import Img from 'gatsby-image'
import LinkOrAction from './LinkOrAction'
import { Link } from 'gatsby-plugin-intl'
import { FaEdit } from 'react-icons/fa'

const SectionDoubleText = ({ block, isAdmin, section_id }) => {
    return (
        <section id={section_id} className="block features-block double-text bg-null outer">
            <div className="inner">
                <div className="grid">
                    <div className="cell block-content block-left">
                        {block.title && block.title.substring(0, 2) != "__" &&
                            <h2 className="block-left-title underline" style={{ paddingLeft: 32 }}>
                                {block.title}
                            </h2>
                        }

                        {block.leftText && block.leftText.childMarkdownRemark.html !== '' && (
                            <div className="block-copy">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: block.leftText.childMarkdownRemark.html,
                                    }}
                                />
                            </div>
                        )}

                        {block.callToAction && (
                            <div className="block-buttons">
                                <LinkOrAction title={block.callToAction.title} color={'primary'} height={2} length={66} />
                            </div>
                        )}
                    </div>
                    <div className="cell block-content block-right">
                        {block.rightText && block.rightText.childMarkdownRemark.html !== '' && (
                            <div className="block-copy">
                                <div
                                    style={{}}
                                    dangerouslySetInnerHTML={{
                                        __html: block.rightText.childMarkdownRemark.html,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SectionDoubleText
