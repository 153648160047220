import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import styles from './blog.module.css'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'

import { injectIntl, Link, FormattedMessage } from 'gatsby-plugin-intl'
class LandingIndex extends React.Component {
    render() {
        const siteTitle = get(this, 'props.data.site.siteMetadata.title')
        const posts = get(this, 'props.data.allContentfulLanding.edges')

        return (
            <Layout location={this.props.location}>
                <div style={{ background: '#fff' }}>
                    <Helmet title={siteTitle} />
                    <div className={styles ? styles.hero : ''}>Blog</div>
                    <div className="wrapper">
                        <h2 className="section-headline">Recent articles</h2>
                        <ul className="article-list">
                            {posts.map(({ node }) => {
                                return (
                                    <li key={node.slug}>
                                        <ArticlePreview article={node} />
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default injectIntl(LandingIndex)

export const pageQuery = graphql`
    query LandingPageQuery($locale: String) {
        allContentfulLanding(filter: {node_locale: {eq: $locale}}, sort: { fields: [publishDate], order: DESC }) {
            edges {
                node {
                    title
                    publishDate(formatString: "MMMM Do, YYYY")
                    landingBlocks {
                        ... on ContentfulAuthor {
                            id
                            name
                            slug
                            bio
                            featured
                            social
                            createdAt
                            updatedAt
                            internal {
                                type
                            }
                            hidden
                        }
                        ... on ContentfulClearText {
                            id
                            title
                            createdAt
                            updatedAt
                            internal {
                                type
                            }
                            body {
                                id
                                childMarkdownRemark {
                                    id
                                    html
                                }
                            }
                            callToAction {
                                title
                                subtitle
                            }
                            hidden
                        }
                        ... on ContentfulNewsletter {
                            id
                            title
                            createdAt
                            updatedAt
                            internal {
                                type
                            }
                            body {
                                id
                                childMarkdownRemark {
                                    id
                                    html
                                }
                            }
                            placeholder
                            subscribe
                            heroImage {
                                fluid(maxWidth: 1180, background: "rgb:000000") {
                                    ...GatsbyContentfulFluid
                                }
                            }
                            hidden
                        }
                        ... on ContentfulHero {
                            id
                            title
                            slug
                            body {
                                childMarkdownRemark {
                                    html
                                }
                            }
                            heroImage {
                                id
                            }
                            createdAt
                            updatedAt
                            internal {
                                type
                            }
                            heroImage {
                                fluid(maxWidth: 1180, background: "rgb:000000") {
                                    ...GatsbyContentfulFluid
                                }
                            }
                            callToActions {
                                title
                                subtitle
                            }
                            hidden
                        }
                        ... on ContentfulMosaic {
                            id
                            title
                            body {
                                childMarkdownRemark {
                                    html
                                }
                            }
                            heroImage {
                                id
                            }
                            createdAt
                            updatedAt
                            internal {
                                type
                            }
                            heroImage {
                                fluid(maxWidth: 1180, background: "rgb:000000") {
                                    ...GatsbyContentfulFluid
                                }
                            }
                            callToAction {
                                title
                                subtitle
                            }
                            images {
                                id
                                fluid(maxWidth: 1180, background: "rgb:000000") {
                                    ...GatsbyContentfulFluid
                                }
                            }
                            hidden
                        }
                        ... on ContentfulFeatures {
                            id
                            title
                            slug
                            body {
                                childMarkdownRemark {
                                    html
                                }
                            }
                            heroImage {
                                id
                            }
                            createdAt
                            updatedAt
                            internal {
                                type
                            }
                            heroImage {
                                fluid(maxWidth: 1180, background: "rgb:000000") {
                                    ...GatsbyContentfulFluid
                                }
                            }
                            hidden
                        }
                    }
                }
            }
        }
    }
`
