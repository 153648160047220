import React from 'react'
import _ from 'lodash'

import { Link, withPrefix, classNames } from '../utils'
import Icon from './Icon'

export default class Action extends React.Component {
    render() {
        let action = _.get(this.props, 'action', null)
        let action_style = _.get(action, 'style', null) || 'link'
        let action_icon = _.get(action, 'icon', null) || 'arrow-left'
        let action_icon_pos = _.get(action, 'icon_position', null) || 'left'
        let completeUrl = `${this.props.site_url}${action.node_locale}/${action.slug}`
        let className = classNames({
            button: action_style === 'primary' || action_style === 'secondary',
            secondary: action_style === 'secondary',
            'has-icon': _.get(action, 'has_icon', null),
        })

        if (this.props.className) {
            className = this.props.className
        }

        // return (
        //     <Link
        //         to={withPrefix(completeUrl)}
        //         {...(_.get(action, 'new_window', null) ? { target: '_blank' } : null)}
        //         {...(_.get(action, 'new_window', null) || _.get(action, 'no_follow', null)
        //             ? {
        //                   rel: (_.get(action, 'new_window', null) ? 'noopener ' : '') + (_.get(action, 'no_follow', null) ? 'nofollow' : ''),
        //               }
        //             : null)}
        //         className={className}
        //         style={{
        //             textTransform: this.props.uppercase ? 'uppercase' : 'capitalize',
        //             display: 'inline-flex',
        //             alignItems: 'center',
        //         }}
        //     >
        //         {this.props.icon && <this.props.icon style={{ marginRight: 16 }} />}
        //         {_.get(action, 'has_icon', null) && <Icon {...this.props} icon={action_icon} />}
        //         <span style={{ fontWeight: 'bold' }} className={classNames({ 'order-first': action_icon_pos === 'right' })}>
        //             {_.get(action, 'shortName', null)}
        //         </span>
        //     </Link>
        // )

        action.style = action.navButtonType
        action.label = 'test'
        action.url = '/'

        return (
            <Link
                to={withPrefix(completeUrl)}
                {...(_.get(action, 'new_window', null) ? { target: '_blank' } : null)}
                {...(_.get(action, 'new_window', null) || _.get(action, 'no_follow', null)
                    ? { rel: (_.get(action, 'new_window', null) ? 'noopener ' : '') + (_.get(action, 'no_follow', null) ? 'nofollow' : '') }
                    : null)}
                className={classNames({
                    button: action_style === 'primary' || action_style === 'secondary',
                    secondary: action_style === 'secondary',
                    'has-icon': _.get(action, 'has_icon', null),
                })}
            >
                {_.get(action, 'has_icon', null) && <Icon {...this.props} icon={action_icon} />}
                <span className={classNames({ 'order-first': action_icon_pos === 'right' })}>{_.get(action, 'shortName', null)}</span>
            </Link>
        )
    }
}
