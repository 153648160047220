import React from 'react'
import _ from 'lodash'

import { withPrefix, markdownify } from '../utils'
import CtaButtons from './CtaButtons'
import Img from 'gatsby-image'
import LinkOrAction from './LinkOrAction'
import { Link } from 'gatsby-plugin-intl'
import { FaEdit } from 'react-icons/fa'

const SectionLogin = ({ block, isAdmin, section_id }) => {
    return (
        <section id={section_id} className={`block features-block bg-null outer ${block.type === "highlight" ? "block-highlight" : ""}`} >
            <div className="inner">
                <div>
                    <div className="cell block-content">
                        {block.title && block.title.substring(0, 2) != "__" &&
                            <h2 className="block-left-title underline" style={{ paddingLeft: 32 }}>
                                {block.title}
                            </h2>
                        }
                        {isAdmin && (
                            <Link
                                style={{ position: 'absolute', top: 16, right: 16 }}
                                to={`https://app.contentful.com/spaces/fsmkvylf1g83/entries/${block.contentful_id}`}
                            >
                                <FaEdit /> Edit
                            </Link>
                        )}
                        {block.body && block.body.childMarkdownRemark.html !== '' && (
                            <div className="block-copy">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: block.body.childMarkdownRemark.html,
                                    }}
                                />
                            </div>
                        )}

                        {block.callToAction && (
                            <div className="block-buttons">
                                <LinkOrAction title={block.callToAction.title} color={'primary'} height={2} length={66} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SectionLogin
