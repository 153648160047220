import React from 'react'
import { navigate } from 'gatsby'
import { isLoggedIn } from '../services/auth'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
    if (typeof window === 'undefined') {
        return null
    }

    if (!isLoggedIn() && location.pathname !== `/app/login`) {
        navigate('/private-area/login')
        return null
    }

    return <Component {...rest} />
}

export default PrivateRoute
