import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import { FaEdit } from 'react-icons/fa'
import LinkOrAction from './LinkOrAction'
import { usePalette } from 'react-palette'
import {
    TwitterTimelineEmbed,
    TwitterShareButton,
    TwitterFollowButton,
    TwitterHashtagButton,
    TwitterMentionButton,
    TwitterTweetEmbed,
    TwitterMomentShare,
    TwitterDMButton,
    TwitterVideoEmbed,
    TwitterOnAirButton,
} from 'react-twitter-embed'

const SectionCalendar = ({ theme, block, isAdmin, section_id }) => {
    let content = (
        <div
            dangerouslySetInnerHTML={{
                __html: block.embed.childMarkdownRemark.html,
            }}
        />
    )

    return (
        <section
            id={section_id}
            className="block hero-block bg-accent outer"
            style={{
                overflow: 'hidden',
            }}
        >
            {isAdmin && (
                <Link
                    style={{ position: 'absolute', top: 16, right: 16 }}
                    to={`https://app.contentful.com/spaces/fsmkvylf1g83/entries/${block.contentful_id}`}
                >
                    <FaEdit /> Edit
                </Link>
            )}

            {content}
        </section>
    )
}

export default SectionCalendar
