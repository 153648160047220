import React from 'react'
import _ from 'lodash'

import { withPrefix, markdownify } from '../utils'
import CtaButtons from './CtaButtons'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import LinkOrAction from './LinkOrAction'
import { Link } from 'gatsby-plugin-intl'
import { FaEdit } from 'react-icons/fa'
import { useMediaQuery } from 'react-responsive'

const SectionMosaic = ({ block, isAdmin, section_id }) => {
    const images = block.images

    const backgroundFluidImageStack = [block.heroImage.fluid].reverse()
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isNormalScreen = useMediaQuery({ query: '(min-width: 1100px)' })

    return (
        <BackgroundImage
            id={section_id}
            className="block hero-block bg-accent outer"
            style={{
                overflow: 'hidden',
            }}
            fluid={backgroundFluidImageStack}
        >
            {isAdmin && (
                <Link
                    style={{ position: 'absolute', top: 16, right: 16 }}
                    to={`https://app.contentful.com/spaces/fsmkvylf1g83/entries/${block.contentful_id}`}
                >
                    <FaEdit /> Edit
                </Link>
            )}

            <div className="inner">
                <div>
                    <h2 className="block-title" style={{ textAlign: 'right', paddingRight: 32 }}>
                        {block.title}
                    </h2>
                    <div
                        className="block-copy"
                        style={{ float: 'right', right: 0, textAlign: 'right', borderRight: 'solid 16px', paddingRight: 16, width: '45%' }}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: block.body.childMarkdownRemark.html,
                            }}
                        />
                    </div>

                    {block.callToActions && block.callToActions.length > 0 && (
                        <div className="block-buttons">
                            {block.callToActions.map(({ title, subtitle }, index) => (
                                <LinkOrAction key={index} title={title} subtitle={subtitle} />
                            ))}
                        </div>
                    )}

                    {isNormalScreen && isBigScreen && (
                        <>
                            <Img
                                style={{ position: 'absolute', top: 120, left: 710, objectFit: 'cover', width: 256, height: 418 }}
                                alt={block.title}
                                fluid={images[0].fluid}
                            />
                            <Img
                                style={{ position: 'absolute', objectFit: 'cover', top: 420, left: 974, width: 256, height: 300 }}
                                alt={block.title}
                                fluid={images[1].fluid}
                            />
                            <Img
                                style={{ position: 'absolute', objectFit: 'cover', top: 500, left: 1238, width: 380, height: 400 }}
                                alt={block.title}
                                fluid={images[2].fluid}
                            />

                            <Img
                                style={{ position: 'absolute', objectFit: 'cover', top: 440, left: 322, width: 380, height: 350 }}
                                alt={block.title}
                                fluid={images[3].fluid}
                            />
                            <Img
                                style={{ position: 'absolute', objectFit: 'cover', top: 546, left: 710, width: 256, height: 350 }}
                                alt={block.title}
                                fluid={images[4].fluid}
                            />
                            <Img
                                style={{ position: 'absolute', objectFit: 'cover', top: 728, left: 974, width: 256, height: 350 }}
                                alt={block.title}
                                fluid={images[5].fluid}
                            />
                        </>
                    )}

                    {isNormalScreen && !isBigScreen && (
                        <>
                            <Img
                                style={{ position: 'absolute', top: 120, left: 40, objectFit: 'cover', width: 256, height: 418 }}
                                alt={block.title}
                                fluid={images[0].fluid}
                            />
                            <Img
                                style={{ position: 'absolute', objectFit: 'cover', top: 380, left: 350, width: 256, height: 300 }}
                                alt={block.title}
                                fluid={images[1].fluid}
                            />
                            <Img
                                style={{ position: 'absolute', objectFit: 'cover', top: 400, left: 660, width: 380, height: 400 }}
                                alt={block.title}
                                fluid={images[2].fluid}
                            />

                            <Img
                                style={{ position: 'absolute', objectFit: 'cover', top: 684, left: 60, width: 380, height: 350 }}
                                alt={block.title}
                                fluid={images[3].fluid}
                            />
                            <Img
                                style={{ position: 'absolute', objectFit: 'cover', top: 720, left: 448, width: 256, height: 350 }}
                                alt={block.title}
                                fluid={images[4].fluid}
                            />
                            <Img
                                style={{ position: 'absolute', objectFit: 'cover', top: 780, left: 800, width: 256, height: 350 }}
                                alt={block.title}
                                fluid={images[5].fluid}
                            />
                        </>
                    )}

                    {!isNormalScreen && !isBigScreen && (
                        <>
                            <Img
                                style={{ position: 'absolute', top: 120, left: 20, objectFit: 'cover', width: 180, height: 418 }}
                                alt={block.title}
                                fluid={images[0].fluid}
                            />
                            <Img
                                style={{ position: 'absolute', objectFit: 'cover', top: 560, left: 20, width: 150, height: 150 }}
                                alt={block.title}
                                fluid={images[1].fluid}
                            />
                            <Img
                                style={{ position: 'absolute', objectFit: 'cover', top: 580, left: 200, width: 150, height: 150 }}
                                alt={block.title}
                                fluid={images[2].fluid}
                            />

                            <Img
                                style={{ position: 'absolute', objectFit: 'cover', top: 720, left: 0, width: 200, height: 300 }}
                                alt={block.title}
                                fluid={images[3].fluid}
                            />
                            <Img
                                style={{ position: 'absolute', objectFit: 'cover', top: 760, left: 160, width: 200, height: 300 }}
                                alt={block.title}
                                fluid={images[4].fluid}
                            />
                            <Img
                                style={{ position: 'absolute', objectFit: 'cover', top: 780, left: 320, width: 200, height: 300 }}
                                alt={block.title}
                                fluid={images[5].fluid}
                            />
                        </>
                    )}

                    <div style={{ height: 960 }}></div>
                </div>
            </div>
        </BackgroundImage>
    )
}
export default SectionMosaic
