import React from 'react'

export default function LinkOrAction({ title, subtitle, color, height, length }) {
    const hrClassName = color === 'primary' ? 'hr-primary' : ''

    return (
        <div style={{ marginLeft: 48 }}>
            <h6 style={{ margin: 0 }}>{title}</h6>
            <hr className={hrClassName} style={{ marginTop: 20, marginBottom: 12, height: height ? height : 3, width: length ? length : 60 }} />
            {subtitle && <h4 style={{ margin: 0 }}>{subtitle}</h4>}
        </div>
    )
}
